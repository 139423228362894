import React, { useEffect, useReducer, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Camera, ChevronRight, LogOut } from 'lucide-react';
import Pica from 'pica';
import { useDispatch, useSelector } from 'react-redux';
import { translateAuthority } from '@/services/TranslationService';
import {
  NovovilleButton,
  NovovilleInput,
  Typography,
} from '@/components/novoville';
import { PhoneInput } from '@/components/ui/phone-input';
import { Separator } from '@/components/ui/separator';
import { toast } from '@/components/ui/use-toast';
import FaceBookIcon from '../../../src/resources/Facebook.svg';
import GoogleIcon from '../../../src/resources/Google.svg';
import UnverifiedIcon from '../../resources/Unverified.svg';
import { getLanguage } from '../../services/GNRL';
import { translate } from '../../services/TranslationService';
import * as AnalyticsActions from '../../services/analyticsActions';
import store from '../../services/store';
import {
  goTo,
  goToAddMobile,
  goToAuthoritySelection,
  goToVerifyEmail,
} from '../../shared/actions';
import { initialProcess } from '../../shared/actions';
import LanguageSelect from '../AuthHeader/LanguageSelect';
import { setCameFrom } from '../home/actions';
import { isEmailDummy } from '../profile/profileUtilities';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import { changeAndPostProfile } from './actions';

const { persistor } = window;
const pica = new Pica();

const boundInitialProcess = bindActionCreators(
  { initialProcess },
  store.dispatch
).initialProcess;

const profileReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_LOADING':
      return { ...state, loading: action.loading };
    default:
      return state;
  }
};

const Profile = (props) => {
  const [language, setLanguage] = useState(getLanguage());

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };
  const dispatch = useDispatch();
  const fileInput = useRef();

  const { profile, hasVerifiedEmail, hasVerifiedPhoneNumber } = useSelector(
    (state) => state.profileReducer
  );

  const socialMediaLogin = useSelector((state) => state.authReducer.login_type);

  const isAthens = useSelector(
    (state) => state.otherReducer.params.setAuthority === 'athens'
  );

  const { email: emailProfile } = profile;
  const emailDummy = isEmailDummy(emailProfile);

  const initialState = {
    loading: false,
    email: '',
    name: '',
    surname: '',
    nickname: '',
    authorityName: translateAuthority(profile.authority.name),
    mobile: '',
    landline: '',
    dataUrlForAvatar: '',
    profileChanged: false,
  };

  const [state, reducerDispatch] = useReducer(profileReducer, initialState);

  useEffect(() => {
    props.setHeaderTitle(translate('sideMenu.Profile'));

    reducerDispatch({
      type: 'SET_FIELD',
      field: 'email',
      value: profile.email,
    });
    reducerDispatch({
      type: 'SET_FIELD',
      field: 'name',
      value: profile.name,
    });
    reducerDispatch({
      type: 'SET_FIELD',
      field: 'surname',
      value: profile.surname,
    });
    reducerDispatch({
      type: 'SET_FIELD',
      field: 'nickname',
      value: profile.nickname,
    });
    reducerDispatch({
      type: 'SET_FIELD',
      field: 'authorityName',
      value: profile.authority.name,
    });
    reducerDispatch({
      type: 'SET_FIELD',
      field: 'mobile',
      value: profile.mobile,
    });
    reducerDispatch({
      type: 'SET_FIELD',
      field: 'landline',
      value: profile.landline,
    });
    reducerDispatch({
      type: 'SET_FIELD',
      field: 'dataUrlForAvatar',
      value: profile.avatar,
    });

    return () => {
      dispatch(setCameFrom('profile'));
    };
  }, [profile, language]);

  useEffect(() => {
    reducerDispatch({
      type: 'SET_FIELD',
      field: 'authorityName',
      value: translateAuthority(profile.authority.name),
    });
  }, [language, profile.authority.name]);

  const onChange = (field, value) => {
    if (field === 'avatar' && isFileImage(value)) {
      const reader = new FileReader();
      let imageName = value.name;
      reader.readAsDataURL(value);
      reader.onload = (res) => {
        const image = new Image();
        image.src = res.target.result;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          if (image.width >= image.height) {
            canvas.width = Math.min(800, image.width);
            canvas.height = Math.round(
              image.height * (canvas.width / image.width)
            );
          } else {
            canvas.height = Math.min(800, image.height);
            canvas.width = Math.round(
              image.width * (canvas.height / image.height)
            );
          }
          pica
            .resize(image, canvas, { quality: 0 })
            .then((resizeResult) =>
              pica.toBlob(resizeResult, 'image/jpeg', 0.8)
            )
            .then((blob) => {
              const newAvatarURL = URL.createObjectURL(blob);
              reducerDispatch({
                type: 'SET_FIELD',
                field: 'dataUrlForAvatar',
                value: newAvatarURL,
              });
              reducerDispatch({
                type: 'SET_FIELD',
                field: 'avatar',
                value: blobToFile(blob, imageName),
              });
              reducerDispatch({
                type: 'SET_FIELD',
                field: 'profileChanged',
                value: true,
              });
            });
        };
      };
    } else {
      reducerDispatch({ type: 'SET_FIELD', field, value });
      reducerDispatch({
        type: 'SET_FIELD',
        field: 'profileChanged',
        value: true,
      });
    }
  };

  const validate = () => {
    const validateName = () => {
      return name.length >= 2;
    };

    const validateSurname = () => {
      return surname.length >= 2;
    };

    if (!validateName()) {
      toast({
        variant: 'destructive',
        title: translate('OTHER.errorOcurred'),
        description: translate('NameNeedsToBeAtLeast2Chars'),
      });
      return false;
    }
    if (!validateSurname()) {
      toast({
        variant: 'destructive',
        title: translate('OTHER.errorOcurred'),
        description: translate('SurnameNeedsToBeAtLeast2Chars'),
      });
      return false;
    }
    if (!validateName() || !validateSurname()) return;
    return true;
  };

  const updateProfile = () => {
    if (!validate()) return;

    reducerDispatch({ type: 'SET_LOADING', loading: true });

    dispatch(changeAndPostProfile(state))
      .then(() =>
        toast({
          variant: 'success',
          title: translate('OTHER.success'),
          description: translate('profile_updated_successfully_message'),
        })
      )
      .catch((err) =>
        toast({
          variant: 'destructive',
          title: translate('OTHER.errorOcurred'),
          description: err.details.user_description,
        })
      )
      .finally(() => reducerDispatch({ type: 'SET_LOADING', loading: false }));
  };

  const handleAddMobileClick = () => {
    if (!hasVerifiedEmail) {
      toast({
        variant: 'destructive',
        title: translate('OTHER.errorOcurred'),
        description: translate('verif_email_first'),
      });
    } else {
      dispatch(goToAddMobile('profile'));
    }
  };

  const purgePersistor = () => {
    persistor.purge().then(() => {
      AnalyticsActions.logoutEvent();
      if (isAthens) {
        let currentUrl = window.location.origin;
        window.location.replace(currentUrl + '?displayMessageAuthority=athens');
      } else {
        persistor
          .flush()
          .then(() => {
            boundInitialProcess();
          })
          .catch((e) => console.log(e));
      }
    });
  };

  const isFileImage = (file) => {
    return file && file['type'].split('/')[0] === 'image';
  };

  const blobToFile = (blob, name) => {
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return blob;
  };

  const {
    email,
    name,
    surname,
    nickname,
    authorityName,
    mobile,
    landline,
    loading,
    dataUrlForAvatar,
    profileChanged,
  } = state;

  return (
    <div className="flex sm:p-[40px] sm:h-[calc(100vh-120px)] h-[calc(100vh-80px)] sm:pb-0 p-0">
      <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 sm:overflow-auto overflow-y-scroll">
        <Typography variant="bold_20" className="text-gray-900 pb-[24px]">
          {translate('ProfileDetails')}
        </Typography>

        <div className="flex flex-col gap-[24px] max-w-[344px]">
          <div
            className="relative w-[58px] h-[58px]"
            onClick={() => {
              fileInput.current.click();
            }}
          >
            <img
              className="w-full h-full rounded-full"
              src={dataUrlForAvatar}
              alt="profile"
            />
            <div className="absolute bottom-0 right-0 bg-white rounded-full p-1 cursor-pointer">
              <Camera className="h-4 w-4 text-gray-600" />
            </div>
            <input
              type="file"
              id="avatarFile"
              ref={fileInput}
              accept="image/*"
              onChange={(e) => onChange('avatar', e.target.files[0])}
              style={{ display: 'none' }}
            />
          </div>
          <div>
            <Typography variant="semibold_14" className="pb-[6px]">
              {translate('name')}
            </Typography>
            <NovovilleInput
              value={name}
              onChange={(e) => onChange('name', e.target.value)}
            />
          </div>
          <div>
            <Typography variant="semibold_14" className="pb-[6px]">
              {translate('surname')}
            </Typography>
            <NovovilleInput
              value={surname}
              onChange={(e) => onChange('surname', e.target.value)}
            />
          </div>
          <div>
            <Typography variant="semibold_14" className="pb-[6px]">
              {translate('nickname')}
            </Typography>
            <NovovilleInput
              value={nickname}
              onChange={(e) => onChange('nickname', e.target.value)}
            />
          </div>
          <div className="flex flex-row items-center gap-4">
            {socialMediaLogin !== 'email' && (
              <img
                src={socialMediaLogin === 'google' ? GoogleIcon : FaceBookIcon}
                className="h-5 w-5"
                alt={socialMediaLogin === 'google' ? 'Google' : 'Facebook'}
              />
            )}
            <div className="flex flex-col gap-[6px] justify-between flex-1">
              {emailDummy || (
                <>
                  <Typography variant="semibold_14">Email</Typography>

                  <button
                    className={`flex flex-col gap-[6px] w-full items-start ${
                      socialMediaLogin !== 'email' && 'cursor-default'
                    }`}
                    disabled={socialMediaLogin !== 'email'}
                    onClick={() => {
                      if (socialMediaLogin !== 'email') return;
                      dispatch(goToVerifyEmail({ from: 'profile' }));
                    }}
                  >
                    <div className="flex flex-row w-full justify-between items-center">
                      <Typography
                        variant="regular_16"
                        className={`pb-[6px] ${
                          socialMediaLogin !== 'email' && 'text-gray-500'
                        }`}
                      >
                        {email}
                      </Typography>
                      {socialMediaLogin === 'email' && (
                        <ChevronRight className="h-4 w-4 text-gray-500" />
                      )}
                    </div>
                    {!hasVerifiedEmail && (
                      <div className="flex flex-row items-center gap-[4px] cursor-pointer">
                        <img
                          className="h-[24px] w-[24px]"
                          src={UnverifiedIcon}
                          alt="unverified"
                        />
                        <Typography
                          variant="semibold_16_underlined"
                          className="text-gray-700"
                        >
                          {translate('Verify your email')}
                        </Typography>
                      </div>
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[6px] justify-between flex-1">
            <Typography variant="semibold_14" className="pb-[6px]">
              {translate('mobile')}
            </Typography>
            <button
              className="flex flex-col gap-[6px] w-full items-start"
              onClick={handleAddMobileClick}
            >
              <div className="flex flex-row w-full justify-between items-center">
                <Typography variant="regular_16" className="pb-[6px]">
                  {mobile || translate('Add mobile')}
                </Typography>
                <ChevronRight className="h-4 w-4 text-gray-500" />
              </div>
              {!hasVerifiedPhoneNumber && (
                <div className="flex flex-row items-center gap-[4px] cursor-pointer">
                  <img
                    className="h-[24px] w-[24px]"
                    src={UnverifiedIcon}
                    alt="unverified"
                  />
                  <Typography
                    variant="semibold_16_underlined"
                    className="text-gray-700"
                  >
                    {translate('Verify your mobile')}
                  </Typography>
                </div>
              )}
            </button>
          </div>
          <div>
            <Typography variant="semibold_14" className="pb-[6px]">
              {translate('landline')}
            </Typography>
            <PhoneInput
              setMobile={(e) => onChange('landline', e)}
              defaultCountry="GR"
              placeholder={landline ? `+${landline}` : ''}
            />
          </div>
          <NovovilleButton
            className="sm:w-fit w-full"
            variant="primary"
            onClick={updateProfile}
            disabled={!profileChanged || loading}
          >
            {translate('save_profile_changes')}
          </NovovilleButton>
        </div>

        <Separator className="my-[32px]" />
        <div className="flex flex-col max-w-[344px]">
          <Typography variant="bold_20" className="pb-[24px]">
            {translate('Settings')}
          </Typography>
          <div className="flex flex-col gap-[24px]">
            <div className="flex flex-col gap-[6px]">
              <Typography variant="semibold_14" className="text-gray-800">
                {translate('Language')}
              </Typography>
              <LanguageSelect
                useFullName
                className="sm:w-full"
                onLanguageChange={handleLanguageChange}
              />
            </div>
            <div>
              <Typography
                variant="semibold_14"
                className="text-gray-800 pb-[6px]"
              >
                {translate('changeAuthority')}
              </Typography>
              <button
                className="flex flex-row justify-between items-center w-full"
                onClick={() =>
                  dispatch(setCameFrom('profile')) &&
                  dispatch(goToAuthoritySelection({ from: 'profile' }))
                }
              >
                <Typography variant="regular_16" className="pb-[6px]">
                  {authorityName}
                </Typography>
                <ChevronRight className="h-4 w-4 text-gray-500" />
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  dispatch(goTo('privacy'));
                }}
                className="flex flex-row justify-between items-center w-full"
              >
                <Typography variant="semibold_14" className="text-gray-800">
                  {translate('DataPrivacy')}
                </Typography>
                <ChevronRight className="h-4 w-4 text-gray-500" />
              </button>
            </div>
          </div>
        </div>
        <Separator className="my-[32px]" />
        <div
          className="flex flex-row gap-[8px] items-center cursor-pointer"
          onClick={purgePersistor}
        >
          <LogOut className="h-4 w-4 text-gray-500" />
          <Typography variant="semibold_14" className="text-gray-800">
            {translate('logout')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withNOVOHeader(Profile);
