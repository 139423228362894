import React, { useReducer, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { get } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { z } from 'zod';
import { getLanguage } from '@/services/GNRL';
import { NovovilleButton, Typography } from '@/components/novoville';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { PhoneInput } from '@/components/ui/phone-input';
import { toast } from '@/components/ui/use-toast';
import '../../output.css';
import novoville_logo from '../../resources/novoville_logo.svg';
import { translate } from '../../services/TranslationService';
import { verifyRequest } from '../../shared/actions';
import AuthHeader from '../AuthHeader/AuthHeader';
import AddPhoneVerify from './AddPhoneVerifiy';
import countriesWithFlagImages from './countriesWithFlagImages';

const FormSchema = z.object({
  mobile: z.string().refine((value) => value.length > 7, {
    message: translate('Invalid phone number'),
  }),
});

const AddPhone = (props) => {
  const { history } = props;
  const from = history?.location.state?.state?.from;
  console.log('from', props, from);

  const isAthensIframe = useSelector(
    (state) => get(state, 'otherReducer.params.setAuthority') === 'athens'
  );
  const isPhoneVerified = useSelector(
    (state) => state.profileReducer.hasVerifiedPhoneNumber
  );
  const countries = useSelector((state) => state.countriesReducer.countries);

  const country = useSelector((state) => state.profileReducer.profile.country);

  const initialState = {
    loading: false,
    screen: 'verify_request',
    mobile: '',
    mobileCountryCode: isAthensIframe ? '+30' : '',
    dropDownOpen: false,
    countriesList: countriesWithFlagImages,
    selectedCountry: getLocalModelCountry(
      getCountryFromIsoCode(country.country_iso_code, countries)
    ),
    isMobileValid: false,
  };

  const [mobile, setMobile] = useState(initialState.mobile);
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_LOADING':
        return { ...state, loading: action.payload };
      case 'SET_SCREEN':
        return { ...state, screen: action.payload };
      case 'SET_MOBILE_COUNTRY_CODE':
        return { ...state, mobileCountryCode: action.payload };
      case 'SET_DROP_DOWN_OPEN':
        return { ...state, dropDownOpen: action.payload };
      case 'SET_COUNTRIES_LIST':
        return { ...state, countriesList: action.payload };
      case 'SET_SELECTED_COUNTRY':
        return { ...state, selectedCountry: action.payload };
      case 'SET_IS_MOBILE_VALID':
        return { ...state, isMobileValid: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatchState] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(getLanguage());
  const [recaptchaKey, setRecaptchaKey] = useState(0);

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    setRecaptchaKey((prevKey) => prevKey + 1);
  };

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      mobile: state.mobile,
    },
  });

  const onSubmit = () => {
    const captchaValueParam = recaptchaRef.current.getValue();
    dispatchState({ type: 'SET_LOADING', payload: true });

    dispatch(
      verifyRequest(
        mobileWithCC,
        undefined,
        process.env.REACT_APP_NV_CAPTCHA_KEY,
        captchaValueParam
      )
    )
      .then((res) => {
        dispatchState({ type: 'SET_LOADING', payload: false });
        dispatchState({ type: 'SET_SCREEN', payload: 'verify' });
      })
      .catch((err) => {
        dispatchState({ type: 'SET_LOADING', payload: false });
        toast({
          variant: 'destructive',
          title: translate('OTHER.errorOcurred'),
          description: err?.details?.user_description,
        });
      });
  };

  const recaptchaRef = useRef();

  const onCaptchaChange = (captchaResponse) => {
    dispatchState({ type: 'SET_CAPTCHA_RESPONSE', payload: captchaResponse });
  };
  const onCaptchaExpired = () => {
    dispatchState({ type: 'SET_CAPTCHA_RESPONSE', payload: '' });
  };

  var mobileWithCC = (mobile || '').replace('+', '');

  if (state.screen === 'verify') {
    return (
      <AddPhoneVerify
        mobile={mobileWithCC}
        phoneVerified={isPhoneVerified}
        cameFrom={from}
      />
    );
  }

  return (
    <div className="flex flex-wrap bg-white w-screen">
      <div className="h-[400vh] w-full min-h-[100vh] bg-white overflow-hidden fixed" />
      <AuthHeader
        logo={novoville_logo}
        handleLanguageChange={handleLanguageChange}
      />
      <div className="min-h-[calc(100vh-100px)] w-full self-center flex flex-col justify-center sm:py-[74px] sm:px-8 px-[24px]">
        <Card className="self-center sm:w-full max-w-[468px] drop-shadow-none shadow-none text-gray-800 border-none">
          <section key={'profile'} className="flex flex-column flex-grow">
            <div className="flex flex-column flex-grow flex-top-bottom-margin">
              <div>
                <CardHeader className="text-center p-0 pb-[32px]">
                  <CardTitle>
                    <Typography variant="semibold_24">
                      {translate('mobile_verification')}
                    </Typography>
                  </CardTitle>
                  <CardDescription className="mt-[4px]">
                    <Typography variant="regular_16">
                      {translate('continue_verify')}
                    </Typography>
                  </CardDescription>
                </CardHeader>
                <CardContent className="p-0 flex flex-col items-center">
                  <div className="pb-[32px] grid gap-2 w-full">
                    <Form {...form}>
                      <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-8 flex flex-col items-center w-full"
                      >
                        <FormField
                          control={form.control}
                          name="mobile"
                          render={({ field }) => (
                            <FormItem className="flex flex-col items-start w-full">
                              <FormControl className="w-full">
                                <PhoneInput
                                  className="drop-shadow-md"
                                  setMobile={setMobile}
                                  defaultCountry="GR"
                                  placeholder={translate('enter_mobile_number')}
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <ReCAPTCHA
                          key={recaptchaKey}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          ref={recaptchaRef}
                          sitekey={process.env.REACT_APP_NV_CAPTCHA_KEY}
                          onExpired={onCaptchaExpired}
                          onChange={onCaptchaChange}
                          hl={language === 'gr' ? 'el' : 'en'}
                        />
                        <NovovilleButton
                          type="submit"
                          variant="primary"
                          disabled={state.loading}
                        >
                          {translate('Continue')}
                        </NovovilleButton>
                      </form>
                    </Form>
                  </div>
                </CardContent>
              </div>
            </div>
          </section>
        </Card>
      </div>
    </div>
  );
};

const getCountryFromIsoCode = (countryCode, countries) => {
  let k = countries.find(
    (country) => `${country.country_iso_code}` === countryCode
  );
  return k;
};

const getLocalModelCountry = (country) => {
  let item = countriesWithFlagImages.find(
    (item) =>
      item.code === country.google_code ||
      item.country_code === country.country_code
  );
  return item;
};

export default withRouter(AddPhone); // Ensure the component is wrapped with withRouter
