import { firstUpperCaseLetter } from '@/lib/utils';
import React from 'react';
import { CalendarIcon } from 'lucide-react';
import moment from 'moment';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';
import { Separator } from '@/components/ui/separator';

const CalendarItem = ({ event, onClick }) => {
  const eventTime = event?.start_at?.substr(0, 10);
  return (
    <div
      onClick={onClick}
      className="flex space-y-[1rem] sm:p-[1.25rem] p-0 cursor-pointer"
    >
      <div
        key={event.id}
        className={`flex sm:flex-row flex-col items-start gap-4 bg-white p-4 transition-shadow w-full`}
        onClick={onClick}
      >
        <div className="min-w-[10.75rem] min-h-[5.625rem]">
          <img
            src={event.photo || '/images/img/Default_image_messages.png'}
            alt={event.title}
            className={`mr-4 sm:h-[8.375rem] sm:w-[10.75rem] w-full h-full rounded-lg object-cover`}
          />
        </div>
        <div className="w-full">
          <div className="mb-2 flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <CalendarIcon className="h-5 w-5 text-purpleLink" />
              <Typography variant="semibold_16" className="text-gray-800">
                {firstUpperCaseLetter(
                  translate(event.type) || translate('event')
                )}
              </Typography>
            </div>
            <Typography variant="regular_14" className="text-gray-500">
              {eventTime}
            </Typography>
          </div>
          <Separator className="my-2 bg-gray-300" />
          <Typography
            variant="bold_16"
            className="text-gray-800 break-words pb-[.375rem]"
          >
            {event.title}
          </Typography>
          <Typography
            variant="regular_16"
            className="text-gray-700 line-clamp-3"
          >
            {event.app_type === 'POLL'
              ? `This poll will be active until ${moment(event.valid_until).format('DD-MM-YYYY')}.`
              : event.body}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CalendarItem;
